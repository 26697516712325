.content.detail {
  .container .row {
    margin-bottom: 100px;
  }

  .header {
    span.badge {
      margin-right: 5px;
    }
  }

  .metrics {
    width: 100%;
    display: grid;
    padding: 0 15px;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 25px;
    row-gap: 25px;
    justify-items: stretch;

    @media (min-width: 576px) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 50px;
      row-gap: 50px;
    }
    @media (min-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 75px;
      row-gap: 75px;
    }

    div {
      padding: 35px;
      background-color: $gray-300;
      color: $gray-900;
      border-radius: 8px;

      h2, h6 {
        font-weight: normal;
        font-family: $font-family-sans-serif;
      }

      h2 {
        font-size: 40px;
        margin-bottom: 0;
      }

      h6 {
        font-size: 20px;
      }

      &.good {
        background-color: $green;
        color: $green-text;
      }

      &.bad {
        background-color: $red;
        color: $red-text;
      }

      h2.error {
        color: $red;
      }
    }
  }

  .timerange {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 45%, $gray-300 50%, rgba(0, 0, 0, 0) 55%);

    .inner {
      margin: 0 auto;
      background-color: $body-bg;

      @media (min-width: 576px) {
        width: 30%;
      }
    }
  }
}
