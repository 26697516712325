.content.list {

  button.filter-close {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;

    span.btn-close {
      display: inline-block;
      width: 0.3em;
      height: 0.3em;
      margin-top: 0.3em;
      margin-left: 0.3em;
    }
  }


  .table > :not(:first-child) {
    border-top-color: inherit;
  }

  table th {
    color: $gray-700;

    &:hover {
      cursor: pointer;
      color: $gray-900;
    }

    &.active {
      color: $gray-900;

      svg path {
        stroke: $gray-900;
      }
    }

    svg {
      margin-top: -4px;
      height: 16px;
      width: 16px;

      path {
        stroke: $gray-700;
      }
    }
  }

  tr.firing {
    background-color: $red-text;
    border-left: 5px solid $red;

    span.severity {
      color: $red-text-dark;
    }
  }

  td a {
    text-decoration: none;
  }

  td span.badge:hover {
    cursor: pointer;
  }

  td span.bad {
    color: $red-text-dark;
  }

  span.volume-warning {
    margin-left: 8px;

    svg {
      margin-top: -4px;
    }
  }
}
